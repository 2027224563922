import type { BrandTheme, PartialBrandTheme } from '../../types/BrandTheme'
import { brandTheme } from '../../types/BrandTheme'
import { merge } from 'theme-ui'
import { dirname } from 'path'
import { icons } from './icons'

import './theme.css'

export const jottacloudtheme: BrandTheme = merge.all<
  BrandTheme,
  PartialBrandTheme
>(brandTheme, {
  branding: {
    brand: 'JOTTACLOUD',
    themedir: dirname(new URL(import.meta.url).pathname),
    appIconSvg: './favicon.svg',
    favIconSvg: './favicon.svg',
    metaTitle: 'Jottacloud',
    staticDir: 'jottacloud',
    width: 116,
    height: 25,
    learnMoreLinkWaitlist: 7262803,
    learnMoreLinkSearch: 7262816,
  },
  icons,
  fonts: {
    body: "Inter, 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    heading: "Inter, 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 13, 14, 16, 19, 24, 32, 48, 64],
  fontWeights: {
    light: 300,
    body: 400,
    heading: 500,
    bold: 500,
  },
  colors: {
    text: '#333',
    background: '#fff',
    primary: '#9C61E8',
    secondary: '#fff',
    accent: '#18AC3A',
    accentText: '#fff',
    border: '#ddd',
    gray: '#868F98',
    indicatorText: '#ccc',
    indicatorPurple: '#9c61e8',
    indicatorDate: 'rgba(69, 67, 76, 0.8)',
    onboarding: '#e5a4f4',
  },
  buttons: {
    primary: {
      borderColor: 'accent',
      backgroundColor: 'accent',
      color: 'accentText',
    },
    smartSearchLightButton: {
      bg: '#F5F1FC',
    },
  },
  forms: {
    checkbox: {
      fontSize: '11px',
      borderRadius: '3px',
      border: '1px solid',
    },
  },
  cards: {
    photoUploadSuccess: {
      '--action-button-text-opacity': 1,
    },
    photoUpload: {
      '--action-button-text-opacity': 1,
    },
  },
  layout: {
    appTopToolbar: {
      fontSize: [1, 3, 3],
    },
    settingsContainer: {
      gap: 4,
      p: 4,
    },
    smartPhotoBannerContainer: {
      bg: '#F5F1FC',
    },
  },
  styles: {
    fileIcon: {
      '--file-icon-border-color': '#b3b3b3',
      '--folder-icon-border-color': '#A88DFF',
      '--folder-icon-fill': '#F2EEFF',
    },
    storageProgressBar: {
      backgroundColor: 'success',
    },
    uploadIcon: {
      filter: 'unset',
    },
  },
})
export default jottacloudtheme
